<template>
  <div>
    <div class="content border">
      <div class="tit">分析报告</div>
      <div v-for="(item, index) in reportList" :key="index" class="c-item" @click="showPopup = true">
        <div>{{ index + 1 }}.{{ item }}</div>
        <div>
          付费浏览
        </div>
      </div>
    </div>
    <div class="popup-mask" v-if="showPopup">
      <div class="popup">
        <div class="paycode">
          <img src="../assets/paycode.jpg" class="img-responsive">
          <div class="cancel" @click="showPopup = false">取消</div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
      reportList: [
        '2019-2025年中国民营医疗行业市场前瞻与战略规划分析报告',
        '2019-2025年中国医药行业电子商务市场竞争分析报告',
        '2019-2025年中国远程医疗行业市场前瞻与战略规划分析报告',
        '2019-2025年中国康复医疗行业市场前瞻与战略规划分析报告',
        '2019-2025年中国激光医疗行业行业市场前瞻与战略规划分析报告',
        '2019-2025中国口腔医疗器械行业市场需求规划分析报告',
        '2018年互联网医疗行业研究报告',
      ]
    }
  }
}
</script>

<style scoped>
.content {
  padding: 15px;
  height: 500px;
}

.border {
  height: 500px;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
}

.tit {
  font-size: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  color: #3F89EC
}

.c-item {
  font-size: 17px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-top: 10px;
  color: #5a5858;
  cursor: pointer;
}

.c-item:last-child {
  border-bottom: 0
}

.c-item:hover {
  color: #f80;
}

.popup-mask {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 60;
  background-color: rgba(0, 0, 0, 0.5);
}

.paycode {
  position: fixed;
  inset: 0px;
  margin: auto;
  width: 400px;
  background-color: rgb(255, 255, 255);
  z-index: 99999;
  display: block;
}


.popup {
  width: 87%;
  max-width: 348px;
  border-radius: 8px;
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: 15%;
  z-index: 70;
  transform: translate(-50%, -50%);
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.cancel {
  text-align: center;
  background-color: #eee;
  display: block;
  padding: 10px;
  margin-top: 0;
  cursor: pointer;
  font-size: 20px;
}</style>

